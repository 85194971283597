import React from "react";
import PropTypes from "prop-types";
import LoadIndicator from "devextreme-react/load-indicator";
import { Button as DXButton } from "devextreme-react/button";
import "./button.scss";

const Button = ({text, loading, ...rest}) => {
  return (
    <DXButton className={"button"} {...rest}>
      <span className="dx-button-text">
        {loading ? (
          <LoadIndicator width={"24px"} height={"24px"} visible={true} className={"loader"}/>
        ) : (
          text
        )}
      </span>
    </DXButton>
  );
};

export default Button;

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
