import { getService, postService, putService } from "./api";
const baseUrl = process.env.REACT_APP_PARTICIPANTSUITE_BACKEND_URL;

export async function getAllCohorts(token) {
  const url = `${baseUrl}/bo/cohort/getAllCohorts`;
  const fetchedInstructors = await getService(token, url);
  return fetchedInstructors;
}

export async function fetchCohortById(token, cohortId) {
  const url = `${baseUrl}/bo/cohort/getCohortById?cohort-id=${cohortId}`;
  const fetchedCohort = await getService(token, url);
  return fetchedCohort;
}

export async function createCohort(token, values) {
  const url = `${baseUrl}/bo/cohort/postCohort`;
  const res = await postService(token, url, values);
  return res;
}

export async function updateCohort(token, values) {
  const url = `${baseUrl}/bo/cohort/putCohort`;
  const res = await putService(token, url, values);
  return res;
}

export async function sendSmsToCohort(token, values) {
  const url = `${baseUrl}/bo/sms/sendSmsToCohort`
  const res = await postService(token, url, values)
  return res
}

export async function sendNotificationToCohort(token, values) {
  const url = `${baseUrl}/bo/notification/sendNotificationToCohort`
  const res = await postService(token, url, values)
  return res
}

export async function updateAllowedChaptersForCohort(token, values) {
  const url = `${baseUrl}/bo/cohort/putChaptersOnCourseByCohort`
  const res = await putService(token, url, values)
  return res
}
