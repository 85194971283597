
import { Spinner, MessagePopup } from "../../components";
import { useState, useRef, useEffect, useCallback } from "react";
import { useLogin } from "../../cookies";
import { deleteSessions, getSessionsByCohort, sendAlertSession } from "../../api/session";
import { Accordion, DataGrid } from "devextreme-react";
import { Column, Button as DXGridButton, Scrolling} from "devextreme-react/data-grid";
import { Button as DXButton}  from "devextreme-react";
import { useHistory, useLocation} from "react-router";
import { getAttendanceListPath, getCreateSessionPath, getEditSessionPath } from "../../app-routes";
import { useProgram } from "../../programs";
import notify from "devextreme/ui/notify";
import { confirm } from "devextreme/ui/dialog"
import { getAvatarColor } from "../../utils/utils";
import { useScreenSize } from "../../utils/media-query";
import { sessionAlert } from "../../utils/utils";


const Sessions = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const splittedPath = pathname.split("/");
    const cohortId = splittedPath[splittedPath.length - 1];
    const [allSessions, setAllSessions] = useState();
    const [selectedItems, setSelectedItems] = useState();
    const [cohortName, setCohortName] = useState();
    const isMounted = useRef(false)
    const {token} = useLogin();
    const program = useProgram();
    const { isSmall, isXSmall, isLarge, isXLarge, isMedium} = useScreenSize();
    const [showSmsPopup, setShowSmsPopup] = useState(false)
    const [message, setMessage] = useState("")
    const [focusedSession, setFocusedSession] = useState("")
    const [previewMessage, setPreviewMessage] = useState("")
    const [options, setOptions] = useState({
      method : {
        label : "SMS or Notification ?",
        value : "Notification",
        messagePreview : "Description :",
        dataValue : "method"
      },
      shareLocation : {
        label : "Share Location",
        value : false,
        messagePreview : "Location :",
        dataValue : "location"
      },
      shareDate : {
        label : "Share Date",
        value : false,
        messagePreview : "Date :",
        dataValue : "date"
      },
      shareDescription : {
        label : "Share Description",
        value : false,
        messagePreview : "Description :",
        dataValue : "description"
      }
    })

    useEffect(() => {
        if (!isMounted.current) {
            getSessions();
            isMounted.current = true;
        }
    })

    // Retrieve all sessions of cohort
    const getSessions = async () => {
        const res = await getSessionsByCohort(token, cohortId);
        if (res.isOk) {
            // Filter sessions to retrieve those who have at least one session
            setSelectedItems(res.data.sessionsByCourse)
            setAllSessions(res.data.sessionsByCourse);
            setCohortName(res.data.cohortName)
        }
    }

    // Redirect to a page to create a session
    const handlePushNavigation = (course, cohortCourse) => {
        history.push({ pathname : getCreateSessionPath(program.name, course, cohortId), state: {cohortCourse: cohortCourse}});
    }

    // Delete a session
    const handleDelete = async (session) => {
        const params = {
            cmsCourse: session.cmsCourse,
            sessionNumber: session.sessionNumber,
            cohort : session.cohort
        }
        const res = await deleteSessions(token, params)

        if (res.isOk) {
            notify("Session deleted", "success", 2000);
            getSessions();
        }else {
            notify("Cannot delete session", "error", 2000);
        }
    }

    const confirmDelete = async (rowData) => {
        const result = await confirm(
         "Are you sure you want to delete this session ?",
         "Delete"
        )
        if(result) {
          handleDelete(rowData)
        }
      }

      const handlePushAttendanceList = (data, cohortCourse) => {
          history.push({pathname: getAttendanceListPath(program.name, data.id, cohortId), state: {cohortCourse: cohortCourse} })
      }
      const handlePushEditSession = (data, cohortCourse) => {
        history.push({ pathname: getEditSessionPath(program.name, data.id, cohortId), state: {cohortCourse: cohortCourse}})
    }

    const renderTitle = (props) => {
        const renderImage = () => {
          return props.imageUrl ? (
            <div
              style={{
                background: `url(${props.imageUrl}) no-repeat #fff`,
                backgroundSize: "cover",
              }}
              className={"user-image"}
            />
          ) : (
            <div
              style={{ background: getAvatarColor(props.title) }}
              className={"text-image"}
            >
              {props.title.substring(0, 2)}
            </div>
          );
        };
    
        return (
          <div className={"userinfo"} >
            <div className={"image-container"}>{renderImage()}</div>
            <div className={"username"}>{props.title}</div>
          </div>
        );
      };


    
    const handleSendSmsClick = (e) => {
      setShowSmsPopup(true)
      setPreviewMessage(`You have a new session \n Name : ${focusedSession.name}`)
      setFocusedSession(e)
    }

    const handleCloseSmsPopup = () => {
      setShowSmsPopup(false)
      setMessage("")
    }
    const handleMessageChange = (name, value) => {
      // If second parameter is undefined, it's the message
      if(name === "message"){
        setMessage(value)

      }else{
        setOptions(prevOptions => ({
          ...prevOptions,
          [name]: {
            ...prevOptions[name],
            value: value
          }
        }));
      }

    };
  
    const handleSendSms = async () => {
      const preparedData = {
        type : options.method.value,
        sessionId : focusedSession.id,
        message : message,
        shareLocation : options.shareLocation.value,
        shareDate : options.shareDate.value,
        shareDescription : options.shareDescription.value
      }

      const res = await sendAlertSession(token, preparedData)

      if (res.isOk) {
        notify("Session Alert sent", "success", 2000);
        handleCloseSmsPopup()
      }else {
        notify("Cannot send Session Alert", "error", 2000);
      }
    }

    const renderButtons = (cohortCourse) => {
      return (
        <Column type="buttons" width={150}> 
          <DXGridButton
          hint="attendance list"
          icon="group"
          visible={true}
          onClick={(e) => handlePushAttendanceList(e.row.data, cohortCourse)}
          />
        <DXGridButton
          hint="Send Session Alert"
          icon="message"
          visible={true}
          onClick={(e) => handleSendSmsClick(e.row.data)}
          />
        <DXGridButton
          hint="attendance list"
          icon="edit"
          visible={true}
          onClick={(e) => handlePushEditSession(e.row.data, cohortCourse)}
          />
        <DXGridButton
          hint="delete"
          name="delete"
          visible={true}
          onClick={(e) => confirmDelete(e.row.data)}
          />
    </Column>       
      )
      
    }

    
  const selectionChanged = (e) => {
    let newItems = [...selectedItems];
    e.removedItems.forEach((item) => {
      let index = newItems.indexOf(item);
      if (index >= 0) {
        newItems.splice(index, 1);
      }
    });
    if (e.addedItems.length) {
      newItems = [...newItems, ...e.addedItems];
    }
    setSelectedItems(newItems);
  };

  const renderPreviewMessage = useCallback(() => {
    // Basic preview message
    let preview = `You have a new session \n Name : ${focusedSession.name}`;
    // For each options, if checked, add it to the message preview
    Object.keys(options).forEach((key) => {
      if (options[key].value === true) {
        // If location, show link to google maps
        if (options[key].dataValue === "location") {
          if(focusedSession[options[key].dataValue]) {
            // Get latitude and longitude
            const location = focusedSession[options[key].dataValue];
            preview += `\n ${options[key].messagePreview}  https://maps.google.com/?q=${location.latitude},${location.longitude}`;
          }else {
            preview += `\n ${options[key].messagePreview} No location available`;
          }
          
        } 
        else if(options[key].dataValue === "date"){
          const date = new Date(focusedSession[options[key].dataValue]);
          preview += `\n ${options[key].messagePreview} ${date.toLocaleDateString('en-GB')} ${date.toLocaleTimeString('en-GB')}`;
        } else if(options[key].dataValue === "method"){
            return
        }
        else {
          if(focusedSession[options[key].dataValue]){
            preview += `\n ${options[key].messagePreview}  ${focusedSession[options[key].dataValue]}`;
          }else{
            preview += `\n ${options[key].messagePreview}  No data available`;
          }
        }
      }
    });
    if(message){
      preview += `\nAdditional info :${message}`
    }
    setPreviewMessage(preview);
  }, [focusedSession, options, message]);
  

  const renderItems = (cohortCourse) =>{
return (
  <>
              <DXButton text="Create a new Session" className={"button"} onClick={() => handlePushNavigation(cohortCourse.cmsCourse, cohortCourse)}/>
                  <DataGrid
                  dataSource={cohortCourse.Sessions}
                  showBorders={true}
                  noDataText="No sessions created yet"
                  columnWidth={200}
                  >
                      <Scrolling columnRenderingMode="virtual" />
                      <Column dataField="sessionNumber" caption="#" alignment="left" width={50}/>
                      {(isSmall || isXSmall) && renderButtons(cohortCourse)}
                      
                      <Column dataField="name" />
                      <Column dataField="status"  />
                      <Column dataField="date" dataType="datetime"  />

                      {(isMedium || isLarge || isXLarge) && renderButtons(cohortCourse)} 

                  </DataGrid>
                  </>
    )
  }

    useEffect(() => {
      renderPreviewMessage();
    }, [options, focusedSession, renderPreviewMessage]);


    return (
        <div>
            {allSessions ? (
                <>
                    <h2>Sessions for {cohortName}</h2>
                    <Accordion 
                    selectedItems={selectedItems}
                    onSelectionChanged={selectionChanged}
                    animationDuration={500}
                    multiple={true}
                    dataSource={allSessions}
                    itemTitleRender={renderTitle}
                    itemRender={(cohortCourse) => renderItems(cohortCourse)}
                    focusedSession={focusedSession}
                    
                    />

                    <MessagePopup
                    open={showSmsPopup}
                    message={message}
                    type={sessionAlert}
                    onClose={handleCloseSmsPopup}
                    onMessageChange={handleMessageChange}
                    onSendSms={handleSendSms}
                    previewMessage={previewMessage}
                    options={options}
                  />
                </>
            ) : (
                <Spinner />
            )}
        </div>
    );

};

export default Sessions;
