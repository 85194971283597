import { Map } from "devextreme-react";
import React from "react";
import markerIcon from "../../resources/icons/mapMarker.png";

const MapWidget = ({ markersData, onMapClick }) => {
  const googleApiKey = React.useMemo(
    () => process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    []
  );
  return (
    <Map
      defaultZoom={6}
      height={440}
      width="100%"
      controls={true}
      markerIconSrc={markerIcon}
      markers={markersData}
      apiKey={{ google: googleApiKey }}
      provider="google"
      onClick={onMapClick}
    />
  );
};

export default MapWidget;
